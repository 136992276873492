import Hero from '../../app_components/Hero';
import ContentCard from '../../app_components/ContentCard';
import ContentCardContainer from '../../app_components/ContentCardContainer';

import Intro from './Intro';

import './HomePage.css';

function HomePage() {
  return (
    <>
      <Hero
        h1='Nadja Koskela'
        p='Welcome to my coding playground!'
        contact={false}
        ctaText='Click me'
      />
      <main>
        <Intro />
        <ContentCardContainer
          padding='20%'
          mobilePadding='5%'
          columns='2'
          content={
            <>
              <ContentCard
                symbol='work'
                copy='For business'
                link='/consulting'
              />
              <ContentCard symbol='casino' copy='For fun' link='/games' />
            </>
          }
        />
        <button
          className='button btn-subtle'
          onClick={() =>
            (window.location.href = 'mailto:koskelanadja@gmail.com')
          }
        >
          Get in touch
        </button>
      </main>
    </>
  );
}

export default HomePage;
